.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Utilise toute la hauteur de la vue */
}

.menu {
  flex: 0 0 200px; /* Largeur fixe pour le menu */
  background: #f0f0f0; /* Couleur de fond pour le menu */
  height: 100vh;;
}

.content {
  flex-grow: 1; /* Prend l'espace restant */
  padding: 20px; /* Espacement à l'intérieur du contenu */
  overflow-y: auto; /* Permet le défilement si nécessaire */
}
